import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  displayLogin = true;

  constructor(private router: Router) {

  }
  ngOnInit(): void {
  }

  onClose() {
    this.displayLogin = false;
    this.router.navigate([{outlets: {modal: null}}]);
  }

}
