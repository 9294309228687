import { createReducer, on } from '@ngrx/store';
import { GETADS, SETADS } from '../actions/ads.actions';

export const initialState = [];

const _adsReducer = createReducer(initialState,
  on(SETADS, (state, {payload}) => payload),
  on(GETADS, state => state),
);

export function adSReducer(state, action) {
  return _adsReducer(state, action);
}
