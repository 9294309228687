import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { firestore } from 'firebase/app';
import { map, switchMap, merge } from 'rxjs/operators';
import { Observable, combineLatest, of } from 'rxjs';
import { ErrorMsgService } from 'src/app/shared/error-msg/error-msg.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  constructor(
    private afs: AngularFirestore,
    private auth: AuthService,
    private router: Router,
    private errorMsg: ErrorMsgService,
    private ngxSpinnerService: NgxSpinnerService

  ) { }

  get(chatId) {
    return this.afs
      .collection<any>('chats')
      .doc(chatId)
      .snapshotChanges()
      .pipe(
        map(doc => {
          return { id: doc.payload.id, ...doc.payload.data() };
        })
      );
  }

  getUserChats() {
    return this.auth.user.pipe(
      switchMap(user => {
        const query1 = this.afs
        .collection('chats', ref => ref.where('idUser1', '==', user.id))
        .snapshotChanges();
        const query2 = this.afs
        .collection('chats', ref => ref.where('idUser2', '==', user.id))
        .snapshotChanges();
        return combineLatest(query1, query2).pipe(map(([one, two]) => [...one, ...two]))
          .pipe(
            map(actions => {
              if (actions.length) {
                return actions.map(a => {
                  const data: Object = a.payload.doc.data();
                  const id = a.payload.doc.id;
                  return { id, ...data };
                });
              }
            },
            error => {
              this.errorMsg.add(['une erreur technique est survenue, merci de réessayer plus tard']);
              this.ngxSpinnerService.hide();
            })
          );
      })
    );
  }

  async create(idReceiver) {
    const { id } = await this.auth.getUser();
    const idChat = this.setOneToOneId(idReceiver, id);
    const data = {
      id: idChat,
      idUser1: id,
      idUser2: idReceiver,
      createdAt: Date.now(),
      count: 0,
      messages: []
    };
    const docRef = await this.afs.collection('chats').doc(idChat);
    docRef.get().subscribe((doc) => {
      if (!doc.exists) {
        docRef.set(data); // create the document
      }
    },
    err => {
      this.errorMsg.add(['une erreur technique est survenue, merci de réessayer plus tard']);
      this.ngxSpinnerService.hide();
    });

    return this.router.navigate(['/user-profile/chats/', idChat]);
  }

  setOneToOneId(id1: string, id2: string) {
    if (id1 < id2) {
      return id1 + id2;
    } else {
      return id2 + id1;
    }
  }

  async sendMessage(chatId, idReceiver, content) {
    const { id } = await this.auth.getUser();
    // const idChat = this.setOneToOneId(idReceiver, id);

    const data = {
      receiverId: idReceiver,
      senderId: id,
      messageText: content,
      createdAt: Date.now()
    };

    if (id && idReceiver) {
      const ref = this.afs.collection('chats').doc(chatId);
      return ref.update({
        messages: firestore.FieldValue.arrayUnion(data)
      }).then().catch( () => this.errorMsg.add(['une erreur technique est survenue, merci de réessayer plus tard']));
    }
  }


  joinUsers(chat$: Observable<any>) {
    let chat;
    const joinKeys = {};

    return chat$.pipe(
      switchMap(c => {
        // Unique User IDs
        chat = c;
        const senderId = Array.from(new Set(c.messages.map(v => v.senderId)));
        const receiverId = Array.from(new Set(c.messages.map(v => v.receiverId)));


        const ids = senderId.concat(receiverId);

        const userDocs = ids.map(u =>
          this.afs.doc(`user/${u}`).valueChanges()
        );

        return userDocs.length ? combineLatest(userDocs) : of([]);
      }),
      map(arr => {
        arr.forEach(v =>
          (joinKeys[(<any>v).id] = v));
        chat.messages = chat.messages.map(v => {
          return { ...v, receiverUser: joinKeys[v.receiverId], senderUser: joinKeys[v.senderId] };
        });
        return chat;
      })
    );
  }

}

