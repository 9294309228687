import { Component, OnInit, LOCALE_ID, Inject, HostListener, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../core/auth.service';
import { MenuItem } from 'primeng/api';
import { Subscription, Observable } from 'rxjs';
import { ShoppingCartService } from 'src/app/core/shopping-cart.service';
import { AdService } from '../../core/ad.service';
import { Store, select } from '@ngrx/store';
import { State } from 'src/app/store/reducers';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  display = false;
  displaySignUp = false;
  items: MenuItem[];
  private cartSubscription: Subscription;
  countTest = 0;
  showDropdown = false;
  languageList = [
    { code: 'en', label: 'English' },
    { code: 'fr', label: 'Français' }
  ];
  count$: Observable<number>;

  constructor(
    private router: Router,
    public auth: AuthService,
    public adService: AdService,
    private _el: ElementRef,
    public shoppingCartService: ShoppingCartService,
    @Inject(LOCALE_ID) protected localeId: string,
    private store: Store<State>) {
      this.count$ = store.pipe(select('counter'));
      auth.user.subscribe((user) => {
        if (user) {
          this.display = false;
        }
      });
  }

  @ViewChild('toggle')
  toggle: ElementRef;

  // @HostListener('document:click', ['event'])
  // private clickedOutside(event): void {
  //     this.closeNavBar();
  // }


  ngOnInit() {
    this.items = [
      { label: 'Mon compte' },
      { label: 'Déconnexion' }
    ];

  }

//   closeNavBar () {
//     const element = this.toggle.nativeElement;
//     if (element.getAttribute( 'aria-expanded' ) === 'true') {
//       element.click();

//     }
// }

  goHome() {
    this.router.navigate(['search']);
  }
  openCart() {
    this.router.navigate(['cart']);
  }
  showSignInModal() {
    this.display = true;
  }
  showSignUpModal() {
    this.displaySignUp = true;
  }
  goToNewAd() {
    this.router.navigate(['new-ad']);
  }
  goToCustomizedOrder() {
    this.router.navigate(['customize-your-order']);
  }

}
