import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from './auth.service';
import { HttpModule } from '@angular/http';
import { SpecialitieService } from './specialities.service';
import { TagService } from './tags.service';
import { AuthGuard } from './auth.guard';
import { GeoService } from './geo.service';
import { UserService } from './user.service';
import { AdService } from './ad.service';
import { ShoppingCartService } from './shopping-cart.service';
import { LocalStorageServie, StorageService } from './storage.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './token.interceptor';

@NgModule({
  imports: [
    CommonModule,
    HttpModule
  ],
  declarations: [],
  providers: [
    AuthService,
    SpecialitieService,
    TagService,
    AuthGuard,
    GeoService,
    UserService,
    AdService,
    ShoppingCartService,
    LocalStorageServie,
    { provide: StorageService, useClass: LocalStorageServie },
    {
      deps: [StorageService, AdService],
      provide: ShoppingCartService,
      useClass: ShoppingCartService
    },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true }
  ]
})
export class CoreModule { }
