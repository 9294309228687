import { Component, OnDestroy, OnInit } from '@angular/core';
import { Ad } from '../models/ad';
import { ShoppingCart } from '../models/shopping-cart';
import { ShoppingCartService } from '../core/shopping-cart.service';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { CartItem } from '../models/cart-item';
import { User } from '../models/user';
import { Router } from '@angular/router';
import { AuthService } from '../core/auth.service';
import { ErrorMsgService } from '../shared/error-msg/error-msg.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Store } from '@ngrx/store';
import { DECREMENT, INCREMENT, RESET, SETCOUNTER } from '../store/actions/counter.actions';
import { State } from '../store/reducers';


const timeout = 600;

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})


export class CartComponent implements OnInit, OnDestroy {
  public products: Observable<Ad[]>;
  public cart: ShoppingCart;
  public itemCount: number;
  public cartItems: CartItem[];
  public display = false;
  public currentVendor: User = new User();
  public currentUser: Observable<User>;
  private cartSubscription: Subscription;

  public constructor(
    private shoppingCartService: ShoppingCartService,
    private router: Router,
    private auth: AuthService,
    private errorMsg: ErrorMsgService,
    private ngxSpinnerService: NgxSpinnerService,
    private store: Store<State>
  ) {
  }

  public emptyCart(): void {
    this.store.dispatch(RESET());
    this.shoppingCartService.empty();
  }

  public ngOnInit(): void {
    document.querySelector('.app-container').scrollTop = 0;
    this.ngxSpinnerService.show();
    this.errorMsg.remove();
    this.currentUser = this.auth.user;
    this.cartSubscription = this.shoppingCartService.get().subscribe((cart) => {
      this.itemCount = cart.items.map((x) => x.quantity).reduce((p, n) => p + n, 0);
      this.cart = cart;
      console.log('cart', this.cart);
    });
    this.ngxSpinnerService.hide();
  }

  public ngOnDestroy(): void {
    if (this.cartSubscription) {
      this.cartSubscription.unsubscribe();
    }
  }

  showVendorInfo(vendor: any) {
    this.currentVendor = vendor;
    this.display = true;
  }

  removeAdFromCart(ad: Ad, quantity: number) {
    this.shoppingCartService.removeItem(ad, quantity);
  }

  goToCheckout() {
    const sub2 = this.currentUser.subscribe((user) => {
      if (user) {
        this.router.navigate([{outlets: { 'modal': null }}]);
        this.router.navigate(['checkout']);

      } else {
        this.router.navigate([{outlets: { 'modal': ['signup'] }}]);
        }
      });
      this.cartSubscription.add(sub2);
  }

  updateQuantity(ad: Ad, quantity) {
    this.shoppingCartService.updateItem(ad, quantity);
  }

}
