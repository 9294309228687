import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VendorInfoComponent } from './vendor-info.component';
import { VendorOpinionsComponent } from './vendor-opinions/vendor-opinions.component';
import { vendorInfoRoutingModule } from './vendor-info.routing';
import { RatingModule } from 'primeng/rating';
import { ButtonModule } from 'primeng/button';
import { FormsModule } from '@angular/forms';
import { NgxLoadingModule } from 'ngx-loading';
import { SharedModule } from '../shared/sharedModule';




@NgModule({
  imports: [
    // CommonModule,
    vendorInfoRoutingModule,
    RatingModule,
    ButtonModule,
    // FormsModule,
    SharedModule,
    NgxLoadingModule.forRoot({})
  ],
  declarations: [
    VendorInfoComponent,
    VendorOpinionsComponent
  ],
  providers: [
  ],
  exports: [
    VendorInfoComponent,
    VendorOpinionsComponent
  ]
})
export class VendorInfoModule { }
