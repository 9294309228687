import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';
import { ShoppingCartService } from './core/shopping-cart.service';
import { Store } from '@ngrx/store';
import { SETCOUNTER } from './store/actions/counter.actions';
import { State } from './store/reducers';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'front-project';
  currentUrl = '/search';

  constructor(private router: Router,
      private location: Location,
      private shoppingCartService: ShoppingCartService,
      private store: Store<State>
     ) {
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.currentUrl = e.url;
      }
    });
  }

  ngOnInit() {
    this.shoppingCartService.get().subscribe((cart) => {
      const itemCount = cart.items.map((x) => x.quantity).reduce((p, n) => p + n, 0);
        this.store.dispatch(SETCOUNTER({payload: itemCount}));
    });
  }

  GoBack() {
    this.location.back();
  }
}
