import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorMsgService } from 'src/app/shared/error-msg/error-msg.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  displayLogin = true;

  constructor(private router: Router,
  private errorMsg: ErrorMsgService) {

  }
  ngOnInit(): void {
    this.errorMsg.remove();
  }

  onClose() {
    this.displayLogin = false;
    this.router.navigate([{outlets: {modal: null}}]);
    // this.router.navigate(['/']);
  }
}
