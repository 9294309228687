import { Component, OnInit, Input } from '@angular/core';
import { EvaluationService } from 'src/app/core/evaluation.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-vendor-opinions',
  templateUrl: './vendor-opinions.component.html',
  styleUrls: ['./vendor-opinions.component.scss']
})
export class VendorOpinionsComponent implements OnInit {
  @Input() id: number;
  evaluations: Observable<any>;
  pageSize = 2;
  page = 1;

  constructor(private evalService: EvaluationService) { }

  ngOnInit() {
    this.evaluations = this.evalService.getUserReceivedRatings(this.id);
  }

  fromTimestampToDate(obj) {
    return new Date(obj).toLocaleDateString();

  }

}
