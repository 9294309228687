import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';


@Injectable()

export class TagService  {

  constructor( private afs: AngularFirestore) {
  }
  public getTagByURL(url : any)
  {
   return this.afs.doc(url);
  }

  public getAllTags(){

  return this.afs.collection('tag').get();

  }

}
