import { Injectable, Output, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorMsgService {
  errors: Array<string> = [];
  @Output() changeEvent: EventEmitter<Array<string>> = new EventEmitter;
  idElement = 'message-erreur';

  public add(errors: Array<string>) {
    this.errors = errors;
    this.changeEvent.emit(this.errors);
    document.querySelector('.app-container').scrollTop = 0;
  }
  public remove() {
    this.errors = [];
    this.changeEvent.emit(this.errors);
  }
}
