import { User } from './../../../models/user';
import { AuthService } from './../../../core/auth.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Validators } from '@angular/forms';
import { ErrorMsgService } from '../../../shared/error-msg/error-msg.service';

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss']
})
export class PasswordComponent implements OnInit {
  passwordForm: FormGroup;
  currentUser: User = new User();
  validationMessages = {
    password: {
      'required': 'le mot de passe est obligatoire.',
      'pattern': 'le mot de passe doit contenir au moins une lettre et un chiffre.',
      'minlength': 'le mot de passe doit contenir au moins 8 caractères.',
      'maxlength': 'le mot de passe ne doit pas dépasser 25 caractères.',
    }
  };

  constructor(
    private formBuilder: FormBuilder,
    public auth: AuthService,
    private errorMsg: ErrorMsgService) { }

  ngOnInit() {
    this.errorMsg.remove();
    this.passwordForm = this.formBuilder.group({
      ActuelPassword: ['', Validators.required],
      NewPassword1: ['', [
        Validators.required,
        Validators.pattern(/^(?=\D*\d)(?=[^a-z]*[a-z]).{8,25}$/),
        Validators.minLength(8),
        Validators.maxLength(25),
      ]],
      NewPassword2: ['', [
        Validators.required,
        Validators.pattern(/^(?=\D*\d)(?=[^a-z]*[a-z]).{8,25}$/),
        Validators.minLength(8),
        Validators.maxLength(25),
      ]]
    });
  }

  saveForm() {
    if (this.passwordForm.valid) {
      if (this.f.NewPassword1.value !== this.f.NewPassword2.value) {
        this.errorMsg.add(['Le nouveau mot de passe et sa confirmation ne correspondent pas']);
        return;
      }

      this.auth.changePassword(this.f.ActuelPassword.value, this.f.NewPassword1.value);
    }

  }
  get f() { return this.passwordForm.controls; }
}
