import { createReducer, on } from '@ngrx/store';
import { GETADDRESS, SETADDRESS } from '../actions/address.actions';

export const initialState = null;

const _addressReducer = createReducer(initialState,
  on(SETADDRESS, (state, {payload}) => payload),
  on(GETADDRESS, state => state),
);

export function addressReducer(state, action) {
  return _addressReducer(state, action);
}
