import { Ad } from './../models/ad';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { firestore } from 'firebase/app';
import { Observable, BehaviorSubject } from 'rxjs';
import { ErrorMsgService } from '../shared/error-msg/error-msg.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable()

export class AdService {
  private ad: Ad;
  private ads: any;
  private address: any = null;

  constructor(private afs: AngularFirestore,
    private errorMsg: ErrorMsgService,
    private ngxSpinnerService: NgxSpinnerService
) {
  }


  public getAdByURL(url: string) {
    return this.afs.doc(url).valueChanges();
  }

  public setAds(ads: any) {
    this.ads = ads;
  }

  public getAds() {
    return this.ads;
  }

  public set(ad: Ad) {
    this.ad = ad;
  }

  public get(): Ad {
    return this.ad;
  }

  setAddress(addrObj) {
    this.address = addrObj;
  }

  getAddress() {
    return this.address;
  }

  public updateArrayUser(adId, userId) {
    this.afs.collection('ad').doc(adId)
      .update({
        orderedBy: firestore.FieldValue.arrayUnion(userId)
      });
  }

  getAdsByUserId(userId) {
    return this.afs.collection('ad', ref => ref.where('vendor.id', '==', userId).orderBy('creation_date', 'desc'));
  }

  getAdsByUserIdWithLimit(userId, limit) {
    return this.afs.collection('ad', ref => ref.where('vendor.id', '==', userId).orderBy('creation_date', 'desc').limit(limit));
  }

  public decreaseOrderNbr(adId, quantity) {
    const adRef = this.afs.firestore.collection('ad').doc(adId);
    this.afs.firestore.runTransaction(function (transaction) {
      return transaction.get(adRef).then(function (adDoc) {
        if (!adDoc.exists) {
          throw new Error('Document does not exist!');
        }
        const newOrderNbr = adDoc.data().orders_max > quantity ? adDoc.data().orders_max - quantity : 0;
        transaction.update(adRef, { orders_max: newOrderNbr });
        return newOrderNbr;
      });
    }).then(newOrderNbr => {

    }).catch(err => {
      this.errorMsg.add(['une erreur technique est survenue, merci de réessayer plus tard']);
      this.ngxSpinnerService.hide();
    });
  }

  public increaseOrderNbr(adId, quantity) {
    const adRef = this.afs.firestore.collection('ad').doc(adId);

    this.afs.firestore.runTransaction(function (transaction) {
      return transaction.get(adRef).then(function (adDoc) {
        if (!adDoc.exists) {
          throw new Error('Document does not exist!');
        }
        const orderNbr = adDoc.data().orders_max;
        if (orderNbr < quantity) {
          throw new Error('Not enough!');
        }
        const newOrderNbr = adDoc.data().orders_max + quantity;
        transaction.update(adRef, { orders_max: newOrderNbr });
        return newOrderNbr;
      });
    }).then(newOrderNbr => {
    }).catch(err => {
      this.errorMsg.add(['une erreur technique est survenue, merci de réessayer plus tard']);
      this.ngxSpinnerService.hide();
    });
  }

  getLatestAds() {
    return this.afs.collection('ad', ref => ref.orderBy('creation_date', 'desc').limit(10));
  }
}
