import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from 'src/app/core/auth.service';
import { EvaluationService } from 'src/app/core/evaluation.service';
import { Observable } from 'rxjs';
import { Subscription } from 'rxjs/Subscription';
import { ErrorMsgService } from 'src/app/shared/error-msg/error-msg.service';

@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss']
})
export class RatingComponent implements OnInit, OnDestroy {

  evaluation: Observable<any>;
  avgRating: Observable<any>;
  globalNote: number;
  private subscription: Subscription;
  loading = false;

  constructor(
    private auth: AuthService,
    private evalService: EvaluationService,
    private errorMsg: ErrorMsgService) { }

  ngOnInit() {
    this.loading = true;
    this.errorMsg.remove();
    this.subscription = this.auth.user.subscribe(user => {
      this.evaluation = this.evalService.getUserReceivedRatings(user.id);
      this.avgRating = this.evaluation.map(arr => {
        const ratings = arr.map(v => v.rate);
        return ratings.length ? ratings.reduce((total, val) => total + val) / arr.length : 0;
      });
      const sub1 = this.avgRating.subscribe( note => {
        this.globalNote = note;
      });
      this.subscription.add(sub1);
      this.loading = false;
    },
    err => {
      this.errorMsg.add(['une erreur technique est survenue, merci de réessayer plus tard']);
      this.loading = false;

    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  fromTimestampToDate(obj) {
    return new Date(obj).toLocaleDateString();

  }
}



