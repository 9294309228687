import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorMsgService } from 'src/app/shared/error-msg/error-msg.service';

@Component({
  selector: 'app-add-to-cart-modal',
  templateUrl: './add-to-cart-modal.component.html',
  styleUrls: ['./add-to-cart-modal.component.scss']
})
export class AddToCartModalComponent implements OnInit {

  @Input() title: string;
  @Input() displayModalCart: boolean;

  constructor(private router: Router,
  private errorMsg: ErrorMsgService) {

  }
  ngOnInit(): void {
    this.errorMsg.remove();
  }

  onClose() {
 //   this.displayModalCart = false;
  }

  navigateToCart() {
    this.router.navigate([{ outlets: { 'modal': null, primary: ['cart'] }}]);
  }

}
