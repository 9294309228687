import {
  ActionReducer,
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  MetaReducer
} from '@ngrx/store';
import { environment } from 'src/environments/environment';
import { counterReducer } from './counter.reducer';
import { addressReducer } from './address.reducer';
import { Observable, of } from 'rxjs';
import { adSReducer } from './ads.reducer';

export interface State {
 counter: number;
 address: string;
 ads: any;
}

export const initialState: State = {
  counter: 0,
  address: null,
  ads: null
};

export const reducers: ActionReducerMap<State> = {

  counter: counterReducer,
  address: addressReducer,
  ads: adSReducer

};

export const metaReducers: MetaReducer<State>[] = !environment.production ? [] : [];
