import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuModule } from 'primeng/menu';
import { DialogModule } from 'primeng/dialog';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { LoginComponent } from './login/login.component';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { SignupComponent } from './signup/signup.component';
import { GooglePlacesDirective } from './directive/google-places.directive';
import { KeysPipe } from './pipes/keys.pipe';
import { StripePipe } from './pipes/stripe.pipe';
import { TimerComponent } from './timer/timer.component';
import { ErrorMsgComponent } from './error-msg/error-msg.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoginModalComponent } from './login-modal/login-modal.component';
import { SignupModalComponent } from './signup-modal/signup-modal.component';
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';
import { BottomNavbarComponent } from './bottom-navbar/bottom-navbar.component';
import { CountdownModule } from 'ngx-countdown';
import { WithLoadingPipe } from './pipes/with-loading.pipe';
import { MobileAppComponent } from './mobile-app/mobile-app.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

@NgModule({
  imports: [
    CommonModule,
    MenuModule,
    DialogModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    CountdownModule
  ],
  declarations: [
    HeaderComponent,
    FooterComponent,
    LoginComponent,
    SignupComponent,
    GooglePlacesDirective,
    StripePipe,
    KeysPipe,
    TimerComponent,
    ErrorMsgComponent,
    LoginModalComponent,
    SignupModalComponent,
    ConfirmationModalComponent,
    BottomNavbarComponent,
    WithLoadingPipe,
    MobileAppComponent,
    ResetPasswordComponent
  ],
  exports : [
    CommonModule,
    MenuModule,
    DialogModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    CountdownModule,
    HeaderComponent,
    FooterComponent,
    LoginComponent,
    GooglePlacesDirective,
    StripePipe,
    KeysPipe,
    TimerComponent,
    ErrorMsgComponent,
    NgbModule,
    LoginModalComponent,
    ConfirmationModalComponent,
    WithLoadingPipe,
    MobileAppComponent,
    ResetPasswordComponent
  ]
})
export class SharedModule { }
