import { GeoService } from './../../../core/geo.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { Component, OnInit, NgZone, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validator, Validators } from '@angular/forms';
import { AuthService } from '../../../core/auth.service';
import { User } from '../../../models/user';
import { Subscription } from 'rxjs/Subscription';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AlertService } from 'ngx-alerts';
import { ErrorMsgService } from '../../../shared/error-msg/error-msg.service';


@Component({
  selector: 'app-personal-infos',
  templateUrl: './personal-infos.component.html',
  styleUrls: ['./personal-infos.component.scss']
})
export class PersonalInfosComponent implements OnInit, OnDestroy {
  address: any;
  lat: any;
  lng: any;
  center: any;
  userForm: FormGroup;
  urlCoverImg = 'assets/img/cover-empty-img.png';
  urlProfileImg = 'assets/img/profile-empty-img.png';
  user: User = new User();
  coverImg: any;
  profileImg: any;
  userRef: AngularFirestoreDocument<any>;
  private subscription: Subscription;
  submitted = false;


  constructor(
    private zone: NgZone,
    private formBuilder: FormBuilder,
    public auth: AuthService,
    private storage: AngularFireStorage,
    private afs: AngularFirestore,
    private geoService: GeoService,
    private alertService: AlertService,
    private errorMsg: ErrorMsgService
  ) {

  }

  ngOnInit() {
    this.errorMsg.remove();
    this.userForm = this.formBuilder.group({
      Nom: [''],
      Prenom: [''],
      Email: [{ value: '', disabled: true }, Validators.email],
      Telephone: [''],
      Adresse: ['']
    });
    this.subscription = this.auth.user.subscribe((user) => {
      if (user) {
        this.user = user;
        if (user.picture != null) {
          this.urlProfileImg = user.picture;
        }
        if (user.cover_image != null) {
          this.urlCoverImg = user.cover_image;
        }
        this.f.Prenom.patchValue(user.first_name);
        this.f.Nom.patchValue(user.last_name);
        this.f.Email.patchValue(user.email_address);
        this.f.Telephone.patchValue(user.phone_number);
        this.f.Adresse.patchValue(user.address);
        this.userRef = this.afs.doc(`user/${this.user.id}`);
      }
    });

  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  onCoverImgUpload(event) {
    this.coverImg = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (event2: any) => {
      this.urlCoverImg = event2.target.result;
    };
  }

  onProfileImgUpload(event) {
    this.profileImg = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (event2: any) => {
      this.urlProfileImg = event2.target.result;
    };
  }

  saveImgs() {
    let coverImgSnapshot: any;
    let profileImgSnapshot: any;
    let storageCoverImgRef: any;
    let storageProfileImgRef: any;

    if (this.coverImg !== undefined && this.coverImg != null) {
      storageCoverImgRef = this.storage.ref('user/cover/' + (this.user.email_address));
      coverImgSnapshot = storageCoverImgRef.put(this.coverImg).snapshotChanges();
    }
    if (this.profileImg !== undefined && this.profileImg != null) {
      storageProfileImgRef = this.storage.ref('user/profile/' + (this.user.email_address));
      profileImgSnapshot = storageProfileImgRef.put(this.profileImg).snapshotChanges();
    }
    if (coverImgSnapshot !== undefined && coverImgSnapshot != null) {
      let sub2, sub3;
      sub2 = coverImgSnapshot.subscribe(res => {
        const coverDwlUrl = storageCoverImgRef.getDownloadURL();
       sub3 =  coverDwlUrl.subscribe(res1 => {
          this.userRef.set({
            cover_image: res1
          }, { merge: true });
        });
      });
      this.subscription.add(sub2);
      this.subscription.add(sub3);
    }
    if (profileImgSnapshot !== undefined && profileImgSnapshot != null) {
      let sub4, sub5;
      sub4 = profileImgSnapshot.subscribe(res => {
        const profileDwlUrl = storageProfileImgRef.getDownloadURL();
        sub5 = profileDwlUrl.subscribe(res1 => {
          this.userRef.set({
            picture: res1
          }, { merge: true });
        });
      });
      this.subscription.add(sub4);
      this.subscription.add(sub5);
    }

  }

  public updateUserData() {
    let data = <any>{};
    if (this.f.Nom.touched) {
      data.last_name = this.userForm.value.Nom;
    }
    if (this.f.Prenom.touched) {
      data.first_name = this.userForm.value.Prenom;
    }
    if (this.f.Telephone.touched) {
      data.phone_number =  this.userForm.value.Telephone;
    }
    if (this.f.Adresse.touched) {
      if (this.f.Adresse.value) {
        data.address = this.address;
        data.position = this.center;
      } else {
        data.address = null;
        data.position = null;
      }
      this.center = null;
    }

    if (data) {
    this.userRef.set(data, { merge: true }).then( (res) => {
      this.alertService.success('Données enregistrées');
    });
    }
  }
  isEmpty(value) {
    return value == null || value === undefined || value === '';
  }

  saveForm() {
    this.submitted = true;
    if (this.userForm.touched) {
    if (this.f.Adresse.touched && this.f.Adresse.value && !this.center) {
      this.userForm.get('Adresse').setErrors({ ['format']: true });
    }
    if (this.userForm.valid) {
      this.saveImgs();
      this.updateUserData();
    }
  }
  }
  get f() { return this.userForm.controls; }

  setAddress(addrObj) {
    this.zone.run(() => {
      this.address = addrObj.formatted_address;
      this.f.Adresse.patchValue(this.address);
      this.lat = addrObj.lat ? addrObj.lat : addrObj.geometry.location.lat();
      this.lng = addrObj.lng ? addrObj.lng : addrObj.geometry.location.lng();
      this.center = this.geoService.getPoint(this.lat, this.lng);
    });
  }

  isValidAddress(addr) {
    const geocoder = new google.maps.Geocoder();

    geocoder.geocode({
      'address': addr},
      function(results, status) {
      if (status === google.maps.GeocoderStatus.OK && results.length > 0) {
        this.address = results[0].formatted_address;
        this.lat = results[0].geometry.location.lat();
        this.lng = results[0].geometry.location.lng();
        return true;
      } else {
        return false;
      }
    });
  }
}
