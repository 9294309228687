import { SharedModule } from '../../shared/sharedModule';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileRoutingModule } from './profile.routing';
import { ProfileComponent } from './profile.component';
import { VericalMenuComponent } from './vertical-menu/vertical-menu.component';
import { PersonalInfosComponent } from './personal-infos/personal-infos.component';
import { IdentityComponent } from './identity/identity.component';
import { PasswordComponent } from './password/password.component';
import { BankInfosComponent } from './bank-infos/bank-infos.component';
import { RatingComponent } from './rating/rating.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RatingModule } from 'primeng/rating';
import { DialogModule } from 'primeng/dialog';
import { OtherInfosModalComponent } from './other-infos-modal/other-infos-modal.component';
import { OtherInfosComponent } from './other-infos/other-infos.component';
import { NgxLoadingModule } from 'ngx-loading';
import { AddressModalComponent } from './address-modal/address-modal.component';
import { AddressModalInfoComponent } from './address-modal-info/address-modal-info.component';


@NgModule({
  imports: [
    // CommonModule,
    ProfileRoutingModule,
    ReactiveFormsModule,
    // FormsModule,
    // DialogModule,
    RatingModule,
    SharedModule,
    NgxLoadingModule.forRoot({}) ],
  declarations: [
    ProfileComponent,
    VericalMenuComponent,
    PersonalInfosComponent,
    IdentityComponent,
    PasswordComponent,
    BankInfosComponent,
    RatingComponent,
    OtherInfosModalComponent,
    OtherInfosComponent,
    AddressModalComponent,
    AddressModalInfoComponent
  ],
  exports: [
    OtherInfosModalComponent
  ]
})
export class ProfileModule { }
