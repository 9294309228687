import { DatePipe, JsonPipe } from '@angular/common';


export class User {

    public id: string = null;
    public first_name: string = null;
    public last_name: string = null;
    public address: string = null;
    public position = null;
    public phone_number: string = null;
    public rate: number = 0;
    public nbr_opinions: number = 0;
    public subscription_date: any = null;
    public address_city: string = null;
    public email_address: string = null;
    public picture: string = null;
    public cover_image: string = null;
    public stripeCustomerId: string = null;

  //   public constructor(init?: Partial<User>) {
  //     Object.assign(this, init);
  // }

    constructor(data = {
        id : null,
        first_name : null,
        last_name : null,
        address : null,
        email_address : null,
        picture : null,
        cover_image : null,
        address_city : null,
        position : null,
        phone_number : null,
        rate: 0,
        nbr_opinions: 0
    }) {
       this.id = data.id;
       this.first_name = data.first_name;
       this.last_name = data.last_name;
       this.address = data.address;
       this.picture = data.picture;
       this.cover_image = data.cover_image;
       this.address_city = data.address_city;
       this.position = data.position;
       this.phone_number = data.phone_number;
       this.rate = data.rate;
       this.nbr_opinions = data.nbr_opinions;
    }

}
