import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { ErrorMsgService } from '../error-msg/error-msg.service';
import { ShoppingCartService } from 'src/app/core/shopping-cart.service';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { RESET } from 'src/app/store/actions/counter.actions';
import { CountdownComponent, Config } from 'ngx-countdown';
import { State } from 'src/app/store/reducers';


@Component({
  selector: 'timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss']
})
export class TimerComponent implements OnInit {

  ticks = 0;
  @Input() max = 600;

  minutesDisplay = 0;
  hoursDisplay = 0;
  secondsDisplay = 0;
  public itemCount: any;
  @ViewChild('cd') private countdown: CountdownComponent;



  sub: Subscription;
  constructor(
    private shoppingCartService: ShoppingCartService,
    private store: Store<State>
    ) {}

  ngOnInit() {
      this.itemCount = this.store.select('counter');
      this.store.select('counter').distinctUntilChanged().subscribe(c => {
        if (c > 0) {
          this.startTimer();
        }
      });

  }

  private startTimer() {
    if (this.countdown) {
      this.countdown.restart();
    }
  }

  onFinished() {
    this.store.dispatch(RESET());
    this.shoppingCartService.empty();
  }

  countdownConfigFactory(): Config {
    return { template: `$!m!:$!s!`, leftTime: 30 };
  }

}
