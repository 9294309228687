import { Injectable } from '@angular/core';
import { AngularFirestore } from 'angularfire2/firestore';
import { Observable } from 'rxjs';
import { Ad } from '../models/ad';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class VendorInfoService {

  constructor(private afs: AngularFirestore) { }

  getAdsByUserId(userId, limit?): Observable<any> {
    return this.afs.collection('ad', ref =>
    ref.where('vendor.id', '==', userId).where('state', '==', 'new').orderBy('creation_date', 'desc').limit(limit)).valueChanges();
  }
}
