import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-charte-qualite',
  templateUrl: './charte-qualite.component.html',
  styleUrls: ['./charte-qualite.component.scss']
})
export class CharteQualiteComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
