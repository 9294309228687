import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection} from 'angularfire2/firestore';
import { Observable} from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import { Evaluation } from '../models/evaluation';
import { from } from 'rxjs';



@Injectable({
  providedIn: 'root'
})
export class EvaluationService {

  constructor(private afs: AngularFirestore) {}
  // Star reviews that belong to a user
  getUserReceivedRatings(vendorId) {
    const starsRef = this.afs.collection('evaluation', ref => ref.where('vendor_id', '==', vendorId).orderBy('date', 'desc'));
    return starsRef.valueChanges();
  }

// Create or Update star
setEvaluation(ad_id, user_id, user_name, user_picture, vendor_id, rate, description) {
// star document data
  const evaluation = new Evaluation();
  evaluation.deserialize({ad_id, user_id, user_name, user_picture, vendor_id, rate, description});

    // Custom doc ID for relationship
    const starPath = `evaluation/${user_id}_${vendor_id}_${ad_id}`;

    // Set the data, retun the promise
    return from(this.afs.doc(starPath).set(Object.assign({}, evaluation)));

}

}

