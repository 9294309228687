import { Injectable } from '@angular/core';
import { ShoppingCart } from '../models/shopping-cart';
import { Observable } from 'rxjs/Observable';
import { Observer } from 'rxjs/Observer';
import { Ad } from '../models/ad';
import { StorageService } from './storage.service';
import { CartItem } from '../models/cart-item';
import { AdService } from './ad.service';

const CART_KEY = 'cart';


@Injectable({
  providedIn: 'root'
})

export class ShoppingCartService {
  private storage: Storage;
  private subscriptionObservable: Observable<ShoppingCart>;
  private subscribers: Array<Observer<ShoppingCart>> = new Array<Observer<ShoppingCart>>();

  public constructor(
    private storageService: StorageService,
    private adService: AdService
    ) {
    this.storage = this.storageService.get();
    this.subscriptionObservable = new Observable<ShoppingCart>((observer: Observer<ShoppingCart>) => {
      this.subscribers.push(observer);
      observer.next(this.retrieve());
      return () => {
        this.subscribers = this.subscribers.filter((obs) => obs !== observer);
      };
    });
  }

  public get(): Observable<ShoppingCart> {
    return this.subscriptionObservable;
  }

  public addItem(ad: Ad, quantity: number): void {
    const cart = this.retrieve();
    let item = cart.items.find((p) => p.ad.id === ad.id);
    if (item === undefined) {
      item = new CartItem();
      item.ad = ad;
      cart.items.push(item);
    }
    item.quantity += quantity;
    cart.items = cart.items.filter((cartItem) => cartItem.quantity > 0);
    this.calculateCart(cart);
    this.save(cart);
    this.dispatch(cart);
  }

  public updateItem(ad: Ad, quantity: number): void {
    const cart = this.retrieve();
    const oldQuantity = cart.items.find((p) => p.ad.id === ad.id).quantity;
    const newQuantity = quantity - oldQuantity;
    this.adService.decreaseOrderNbr(ad.id, newQuantity);
    cart.items.find((p) => p.ad.id === ad.id).quantity = quantity;
    cart.items = cart.items.filter((cartItem) => cartItem.quantity > 0);
    this.calculateCart(cart);
    this.save(cart);
    this.dispatch(cart);
  }

  public removeItem(ad: Ad, quantity: number): void {
    const cart = this.retrieve();
    this.adService.increaseOrderNbr(ad.id, quantity);
    cart.items.find((p) => p.ad.id === ad.id).quantity -= quantity;
    cart.items = cart.items.filter((cartItem) => cartItem.quantity > 0);
    this.calculateCart(cart);
    this.save(cart);
    this.dispatch(cart);
  }


  public empty(): void {
    const cart = this.retrieve();
    let item;
    for (let i = 0; i < cart.items.length; i++) {
      item = cart.items[i];
      this.adService.increaseOrderNbr(item.ad.id, item.quantity);
    }
    const newCart = new ShoppingCart();
    this.save(newCart);
    this.dispatch(newCart);
  }


  private calculateCart(cart: ShoppingCart): void {
    cart.itemsTotal = cart.items
                          .map((item) => item.quantity * item.ad.final_price)
                          .reduce((previous, current) => previous + current, 0);
  }

  public retrieve(): ShoppingCart {
    const cart = new ShoppingCart();
    const storedCart = this.storage.getItem(CART_KEY);
    if (storedCart) {
      cart.updateFrom(JSON.parse(storedCart));
    }

    return cart;
  }

  private save(cart: ShoppingCart): void {
    this.storage.setItem(CART_KEY, JSON.stringify(cart));

  }

  private dispatch(cart: ShoppingCart): void {
    this.subscribers
        .forEach((sub) => {
          try {
            sub.next(cart);
          } catch (e) {
            // we want all subscribers to get the update even if one errors.
          }
        });
  }

  private cartQuantity (cart: ShoppingCart) {
    return cart.items.map((x) => x.quantity).reduce((p, n) => p + n, 0);

  }


}
