import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { auth } from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { ErrorMsgService } from 'src/app/shared/error-msg/error-msg.service';
import { Observable, of } from 'rxjs';
import { switchMap, first } from 'rxjs/operators';
import { User } from '../models/user';
import { environment } from '../../environments/environment';
import { UserService } from './user.service';
import { AlertService } from 'ngx-alerts';
import { NgxSpinnerService } from 'ngx-spinner';
import { Platform } from '@angular/cdk/platform';
// import { GooglePlusOriginal, GooglePlus} from '@ionic-native/google-plus';
// import * as firebase from 'firebase';

@Injectable()
export class AuthService {

  user: Observable<User>;
  profile_img =
  'https://firebasestorage.googleapis.com/v0/b/my-project-1537129611959.appspot.com/o/user%2Fprofile%2Fuser.PNG?alt=media&token=40b57d49-e118-4be5-ac24-858f18f42af7';

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router,
    private alertService: AlertService,
    private userService: UserService,
    private errorMsg: ErrorMsgService,
    private ngxSpinnerService: NgxSpinnerService,
    // private plateform: Platform,
    // private gplus:

  ) {

    //// Get auth data, then get firestore user document || null
    this.user = this.afAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          return this.afs.doc<User>(`user/${user.uid}`).valueChanges();
        } else {
          return of(null);
        }
      })
    );
  }

  getUser() {
    return this.user.pipe(first()).toPromise();
  }

  // facebookLogin() {
  //   const provider = new auth.FacebookAuthProvider();
  //   return this.oAuthLogin(provider);
  // }

  // twitterLogin() {
  //   const provider = new auth.TwitterAuthProvider();
  //   return this.oAuthLogin(provider);
  // }

  changePassword(currentPassword: string, newPassword: string): any {
    const user = this.afAuth.auth.currentUser;
     this.afAuth.auth.signInWithEmailAndPassword(user.email, currentPassword).then(data => {
      user.updatePassword(newPassword).then( result => {
        this.alertService.success('Mot de passe modifié');
        return true;
      }, error => {
        this.errorMsg.add(['une erreur technique est survenue, merci de réessayer plus tard']);
        return false;
      });
    }, err => {
      this.errorMsg.add(['Le mot de passe actuel est incorrect']);
      return false;
    });

  }

  verifyPassword(code) {
    return this.afAuth.auth.verifyPasswordResetCode(code);
  }

  async emailSignUp(user: User, password: string) {
    return await this.afAuth.auth
      .createUserWithEmailAndPassword(user.email_address, password)
      .then(credential => {
        this.alertService.success('Votre profil a été bien enregistré');
        return this.updateUserData({...user, id: credential.user.uid}); // if using firestore
      })
      .catch(error => {
        this.handleError(error);
      });
  }

  emailLogin(email: string, password: string) {
    return this.afAuth.auth
      .signInWithEmailAndPassword(email, password)
      .then(credential => {
        this.alertService.success('bienvenue!');
        // return this.updateUserData(credential.user);
      })
      .catch(error => this.handleError(error));
  }

  // Sends email allowing user to reset password
  resetPassword(email: string) {
    const fbAuth = auth();

    return fbAuth
    .sendPasswordResetEmail(email, { url: 'https://www.homeat.net/' })
    .then(() => this.alertService.success('Vérifier votre adresse email'))
      .catch(error => this.handleError(error));
  }


  // googleLogin() {
  //   const provider = new auth.GoogleAuthProvider();
  //   // if (this.plateform.ANDROID) {
  //   //   this.nativeGoogleLogin();
  //   // } else {
  //     return this.oAuthLogin(provider);
  //   // }
  // }

  // async nativeGoogleLogin(): Promise<any> {
  //   try {
  //     const gplusUser = await this.gplus.login({
  //       'webClientId' : '784632495172-2e80v3e4m636v08e61nd8aneu45f8h7p.apps.googleusercontent.com',
  //       'offline': true,
  //       'scopes': 'khaoula.elhammoumi@gmail.com'
  //     });
  //     return await this.afAuth.auth
  //     .signInWithCredential(firebase.auth.GoogleAuthProvider.credential(gplusUser.idToken));
  //   } catch (err) {
  //     console.log('err', err);
  //   }
  // }

//   public oAuthLogin(provider) {

//   this.afAuth.auth.getRedirectResult().then((result) => {
//     if (result.credential) {
//       const credential = result.credential;
// console.log('user', result.user);
//       // This gives you a Google Access Token.
//       // You can use it to access the Google API.
//       // The signed-in user info.
//       this.updateUserData(result.user);
//     }
//   });


  // this.afAuth.auth.signInWithRedirect(provider).then(() => {
  //   return this.afAuth.auth.getRedirectResult();
  // }).then(function(result) {
  //   this.updateUserData(result.user);
  // }).catch(error => {
  //   console.log('error', error);
  //   this.handleError(error);
  // });




  //   return this.afAuth.auth.signInWithPopup(provider)
  //     .then((credential) => {
  //       this.updateUserData(credential.user);
  //     })
  //     .catch(error => this.handleError(error));
//   }


  public updateUserData(user: User) {
    // Sets user data to firestore on login
    this.userService.findUserByEmail(user.email_address).subscribe(res => {
      if (res && res.length > 0) {
        return;
      } else {
        const userRef: AngularFirestoreDocument<any> = this.afs.doc(`user/${user.id}`);
        const data = {
          ...user,
          picture: this.profile_img,
          subscription_date: new Date(),
        };
        return userRef.set(data, { merge: true });
      }
    });

  }


  signOut() {
    this.afAuth.auth.signOut().then(() => {
      this.router.navigate(['/search']);
    });
  }

  // If error, console log and notify user
  private handleError(error: Error) {
    this.ngxSpinnerService.hide();
    this.errorMsg.add(['une erreur technique est survenue, merci de réessayer plus tard']);
    this.alertService.danger('Erreur : Identifiant ou mot de passe incorrect');
  }

  // Used by the http interceptor to set the auth header
  getUserIdToken(): Observable<string> {
    return this.afAuth.idToken;
  }

  confirmPasswordReset(actionCode: string, value: any) {
    return this.afAuth.auth.confirmPasswordReset(actionCode, value);
  }


  ///// STRIPE CONNECT //////

  // Login popup window
  stripeLogin() {
    const popup = window.open(`${environment.functionsURL}/stripeRedirect`, '_blank', 'height=700,width=800');
  }
  // Signin with a custom token from
  customSignIn(token) {
    return this.afAuth.auth.signInWithCustomToken(token).then(() => window.close());
  }

}
