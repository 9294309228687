import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-address-modal-info',
  templateUrl: './address-modal-info.component.html',
  styleUrls: ['./address-modal-info.component.scss']
})
export class AddressModalInfoComponent implements OnInit {

  displayLogin = true;

  constructor(private router: Router) {

  }
  ngOnInit(): void {
  }

  onClose() {
    this.displayLogin = false;
    this.router.navigate([{outlets: {modal: null}}]);
    // this.router.navigate(['/']);
  }

}
