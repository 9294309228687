import { Component, OnInit } from '@angular/core';
import { CheckoutService } from 'src/app/checkout/checkout.service';
import { Source } from 'src/app/models/checkout';
import { ErrorMsgService } from '../../../shared/error-msg/error-msg.service';

@Component({
  selector: 'app-bank-infos',
  templateUrl: './bank-infos.component.html',
  styleUrls: ['./bank-infos.component.scss']
})
export class BankInfosComponent implements OnInit {
  sources: Source[];

  constructor(public chk: CheckoutService,
    private errorMsg: ErrorMsgService) { }

  ngOnInit() {
    this.errorMsg.remove();
    this.chk.getCustomer().subscribe(
      data => {
        this.sources = data.sources.data;
      }
    );
  }

  deleteSource(sourceId) {
this.chk.deleteSource(sourceId).subscribe(data => {
  this.sources = this.sources.filter(sources => sources.id !== sourceId);
  });
  }

}
