// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


export const environment = {
  production : false,
  mocked : true,
  devise : false,
  firebase : {
    apiKey: 'AIzaSyBk9IInI0UBS5BDoYHH-pjcxzCJ6uAsn2Y',
    authDomain: 'my-project-1537129611959.firebaseapp.com',
    databaseURL: 'https://my-project-1537129611959.firebaseio.com',
    projectId: 'my-project-1537129611959',
    storageBucket: 'my-project-1537129611959.appspot.com',
    messagingSenderId: '784632495172'

  },
  googleMapsKey: 'AIzaSyD8lcODPDUkeMu_GG5J_Cw6O7ITs808fjw',
  stripePublishable: 'pk_test_j3KKUFzE7IeURBxtAosjv9op',
  functionsURL: 'https://us-central1-my-project-1537129611959.cloudfunctions.net'
  // functionsURL: 'http://localhost:5001/my-project-1537129611959/us-central1'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
