
import { CartItem } from './cart-item';
import { BehaviorSubject } from 'rxjs';

export class ShoppingCart {
  public items: CartItem[] = [];
  public itemsTotal = 0;
  public count = new BehaviorSubject(0);

  public updateFrom(src: ShoppingCart) {
    this.items = src.items;
    this.itemsTotal = src.itemsTotal;
  }
}
