import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-bottom-navbar',
  templateUrl: './bottom-navbar.component.html',
  styleUrls: ['./bottom-navbar.component.scss']
})
export class BottomNavbarComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }

  goToNewAd() {
    this.router.navigate(['new-ad']);
  }
  goToCustomizedOrder() {
    this.router.navigate(['customize-your-order']);
  }


}
