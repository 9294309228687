import { Component, OnInit } from '@angular/core';
import { Route, Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-vertical-menu',
  templateUrl: './vertical-menu.component.html',
  styleUrls: ['./vertical-menu.component.scss']
})
export class VericalMenuComponent implements OnInit {
  currentUrl: string;
  constructor(private router: Router) {
   }


  ngOnInit() {
    let splitedUrl = this.router.url.split('/');
    this.currentUrl = splitedUrl[splitedUrl.length - 1];

    this.router.events.filter((event: any) => event instanceof NavigationEnd)
        .subscribe(event => {
          splitedUrl = event.url.split('/');
          this.currentUrl = splitedUrl[splitedUrl.length - 1];
        });

  }

}
