import { NgModule } from '@angular/core';
// import { CommonModule } from '@angular/common';
import { CartComponent } from './cart.component';
import { cartRoutingModule } from './cart.routing';
import { FormsModule } from '@angular/forms';
import { DialogModule } from 'primeng/dialog';
import { VendorInfoModule } from '../vendor-info/vendor-info.module';
import { ButtonModule } from 'primeng/button';
import { SharedModule } from '../shared/sharedModule';
import { AddToCartInfoComponent } from './add-to-cart-info/add-to-cart-info.component';
import { AddToCartModalComponent } from './add-to-cart-modal/add-to-cart-modal.component';





@NgModule({
  imports: [
    // CommonModule,
    cartRoutingModule,
    // FormsModule,
    // DialogModule,
    VendorInfoModule,
    ButtonModule,
    SharedModule
  ],
  declarations: [
    CartComponent,
    AddToCartInfoComponent,
    AddToCartModalComponent
  ],
  exports: [
    AddToCartInfoComponent,
    AddToCartModalComponent
  ]
})
export class CartModule { }
