import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';


@Injectable()

export class UserService {

  constructor(private afs: AngularFirestore) {
   }

  public getUserByURL(url: string) {
   return this.afs.doc(url).valueChanges();
  }

  public findUserByEmail(email) {
    const userRef = this.afs.collection('user', ref => ref.where('email_address', '==', email ));
    return userRef.valueChanges();
  }

}
