import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-add-to-cart-info',
  templateUrl: './add-to-cart-info.component.html',
  styleUrls: ['./add-to-cart-info.component.scss']
})
export class AddToCartInfoComponent implements OnInit, OnDestroy {


  private subscription: Subscription;
  adTitle = null;

  constructor(private activatedRoute: ActivatedRoute,
              private router: Router) { }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit() {
    this.subscription = this.activatedRoute.params.subscribe(routeParams => {
      this.adTitle = routeParams.adTitle;
    });

  }

  navigateToCart() {
    this.router.navigate([{ outlets: { 'modal': null, primary: ['cart'] }}]);
  }

}
