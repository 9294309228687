import { Component, OnInit } from '@angular/core';
import { ErrorMsgService } from 'src/app/shared/error-msg/error-msg.service';

@Component({
  selector: 'app-proposal-confirmation',
  templateUrl: './proposal-confirmation.component.html',
  styleUrls: ['./proposal-confirmation.component.scss']
})
export class ProposalConfirmationComponent implements OnInit {

  constructor(private errorMsg: ErrorMsgService) { }

  ngOnInit() {
    document.querySelector('.app-container').scrollTop = 0;
    this.errorMsg.remove();
  }

}
