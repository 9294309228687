import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-other-infos-modal',
  templateUrl: './other-infos-modal.component.html',
  styleUrls: ['./other-infos-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OtherInfosModalComponent implements OnInit {
  displayLogin = true;

  constructor(private router: Router, private cdRef: ChangeDetectorRef) {

  }
  ngOnInit(): void {
    this.cdRef.markForCheck();
  }

  onClose() {
    this.displayLogin = false;
    this.cdRef.markForCheck();
    this.router.navigate([{outlets: {modal: null}}]);
  }


}
