import { Directive, ElementRef, OnInit, Output, EventEmitter, NgZone } from '@angular/core';

declare var google: any;

@Directive({
  selector: '[google-place]'
})
export class GooglePlacesDirective implements OnInit {
  private element: HTMLInputElement;
  @Output() onSelect: EventEmitter<any> = new EventEmitter();


  constructor(private elRef: ElementRef, private ngZone: NgZone) {
    this.element = elRef.nativeElement;
   }

   getFormattedAddress(place) {
    // @params: place - Google Autocomplete place object
    // @returns: location_obj - An address object in human readable format
    const location_obj = {};
    for (const i in place.address_components) {
      if (i) {
        const item = place.address_components[i];

        location_obj['formatted_address'] = place.formatted_address;
        location_obj['lat'] = place.geometry.location.lat();
        location_obj['lng'] = place.geometry.location.lng();
        if (item['types'].indexOf('locality') > -1) {
          location_obj['locality'] = item['long_name'];
        } else if (item['types'].indexOf('administrative_area_level_1') > -1) {
          location_obj['admin_area_l1'] = item['short_name'];
        } else if (item['types'].indexOf('street_number') > -1) {
          location_obj['street_number'] = item['short_name'];
        } else if (item['types'].indexOf('route') > -1) {
          location_obj['route'] = item['long_name'];
        } else if (item['types'].indexOf('country') > -1) {
          location_obj['country'] = item['long_name'];
        } else if (item['types'].indexOf('postal_code') > -1) {
          location_obj['postal_code'] = item['short_name'];
        }
      }
    }
    return location_obj;
  }


   ngOnInit() {
    setTimeout(() => {
      const autocomplete = new google.maps.places.Autocomplete(this.element);
      google.maps.event.addListener(autocomplete, 'place_changed', () => {
        this.ngZone.run(() => {

        this.onSelect.emit(this.getFormattedAddress(autocomplete.getPlace()));
        });
      });
      }, 1000);
  }

}
