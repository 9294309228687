import { Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../core/auth.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { GeoService } from 'src/app/core/geo.service';
import { User } from 'src/app/models/user';

// type UserFields = 'nom' | 'prenom' | 'telephone' | 'adresse' | 'email' | 'password' ;
// type FormErrors = { [u in UserFields]: string };

@Component({
  selector: 'app-signup-modal',
  templateUrl: './signup-modal.component.html',
  styleUrls: ['./signup-modal.component.scss']
})
export class SignupModalComponent implements OnInit {

  error: any;
  userForm: FormGroup;
  passReset = false;
  address: any;
  lat: any;
  lng: any;
  center: any;

  validationMessages = {
    nom: {
      'required': 'Le nom est obligatoire.'
    },
    prenom: {
      'required': 'Le prenom est obligatoire.'
    },
    telephone: {
      'required': 'Le numero de telephone est obligatoire.',
      'pattern': 'Le numero de telephone ne peut contenir que des chiffres.'
    },
    adresse: {
      'required': 'L\'adresse postale est obligatoire.'
    },
    email: {
      'required': 'L\'adresse email est obligatoire.',
      'email': 'L\'adresse email est invalide.',
    },
    password: {
      'required': 'Le mot de passe est obligatoire.',
      'pattern': 'Le mot de passe doit contenir au moins une lettre et un chiffre.',
      'minlength': 'Le mot de passe doit contenir au moins 8 caractères.',
      'maxlength': 'Le mot de passe ne doit pas dépasser 25 caractères.'
    },
  };


  constructor(
    private router: Router,
    public authen: AuthService,
    private fb: FormBuilder,
    private zone: NgZone,
    private geoService: GeoService
  ) { }

  ngOnInit() {
    this.buildForm();
  }

  async signup() {
    this.userForm.get('adresse').setErrors({ ['format']: null });
    this.userForm.get('adresse').updateValueAndValidity();
    if (this.f.adresse.touched && this.f.adresse.value && !this.center) {
      this.userForm.get('adresse').setErrors({ ['format']: true });
    }
    if (this.userForm.valid) {
      await this.authen.emailSignUp(this.mapUser(), this.f.password.value);
      return await this.afterSignIn();
    }
  }

  resetPassword() {
    this.authen.resetPassword(this.userForm.value['email'])
      .then(() => this.passReset = true);
  }

  buildForm() {
    this.userForm = this.fb.group({
      nom: ['', Validators.required],
      prenom: ['', Validators.required],
      telephone: ['', [Validators.required, Validators.pattern('[- +()0-9]+')]],
      adresse: ['', Validators.required],
      email: ['', [
        Validators.required,
        Validators.email,
      ]],
      password: ['', [
        Validators.pattern(/^(?=\D*\d)(?=[^a-z]*[a-z]).{8,25}$/),
        Validators.minLength(8),
        Validators.maxLength(25),
      ]],
      agree: ['', Validators.required],
    });

    // this.userForm.valueChanges.subscribe((data) => this.onValueChanged(data));
    // this.onValueChanged(); // reset validation messages
  }


  get f() { return this.userForm.controls; }

  private afterSignIn() {
    this.router.navigate([{outlets: {modal: null}}]);
  }

  setAddress(addrObj) {
    this.zone.run(() => {
      this.address = addrObj['formatted_address'];
      this.lat = addrObj['lat'];
      this.lng = addrObj['lng'];
      this.center = this.geoService.getPoint(this.lat, this.lng);
    });
  }

  private mapUser(): User {
    const user = new User();
    user.first_name = this.f.prenom.value;
    user.last_name = this.f.nom.value;
    user.phone_number = this.f.telephone.value;
    user.email_address = this.f.email.value;
    user.address = this.address;
    user.position = this.center;
    return user;
  }

  public navigateToLogin() {
    this.router.navigate([{outlets: { 'modal': ['login'] }}]);
  }

  public onCGUClick(){
    this.router.navigateByUrl('/cgu');
}
public onCharteClick(){
  this.router.navigateByUrl('/charte-qualite');
}

}
