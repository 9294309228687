
import { Deserializable } from './deserializable';

export class Evaluation implements Deserializable {


    public rate: number;
    public description: string;
    public user_id: string;
    public user_picture: string;
    public user_name: string;
    public date: any;
    public vendor_id: string;
    public ad_id: string;

    constructor() {
        this.date = Date.now();
    }

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }

}
