import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProfileComponent } from './profile.component';
import { PersonalInfosComponent } from './personal-infos/personal-infos.component';
import { IdentityComponent } from './identity/identity.component';
import { PasswordComponent } from './password/password.component';
import { BankInfosComponent } from './bank-infos/bank-infos.component';
import { RatingComponent } from './rating/rating.component';
const routes: Routes = [
    {
        path: '',
        component: ProfileComponent,
        children: [
            {
                path: 'personal-informations',
                component: PersonalInfosComponent
            },
            {
                path: 'identity',
                component: IdentityComponent
            },
            {
                path: 'password',
                component: PasswordComponent
            },
            {
                path: 'bank-informations',
                component: BankInfosComponent
            },
            {
                path: 'rating',
                component: RatingComponent
            }
        ]
    }
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(routes)]
})
export class ProfileRoutingModule { }
