import { Dish } from './dish';
import { User } from './user';

export class Ad {

    id: string;
    title: string;
    starter: any;
    main_course: any;
    dessert: any;
    drink: string;
    speciality = [];
    creation_date: any;
    ready_time: any;
    tag = [];
    orders_max: number;
    ad_adresse: string;
    position: any;
    price: number;
    vendor: User = new User();
    distance: string;
    orderedBy = [];
    state = 'new';
    final_price: number;

    constructor(data: any = {
        id: null,
        title: null,
        starter: null,
        main_course: null,
        dessert: null,
        vendor: new User(),
        creation_date: null,
        ready_time: null,
        ready_at: null,
        tag: [],
        orderedBy: [],
        speciality: [],
        orders_max: null,
        ad_adresse: null,
        position: null,
        ad_lat: null,
        ad_lan: null,
        price: null,
        state: null,
        queryMetadata: {
            distance: null
        },
        final_price: null
    }) {
        this.id = data.id;
        this.title = data.title;
        this.starter = data.starter;
        this.main_course = data.main_course;
        this.dessert = data.dessert;
        this.creation_date = data.creation_date;
        this.orders_max = data.orders_max;
        this.ready_time = data.ready_time;
        this.price = data.price;
        this.ad_adresse = data.ad_adresse;
        this.position = data.position;
        this.tag = data.tag;
        this.speciality = data.speciality;
        this.vendor = data.vendor;
        this.distance = data.queryMetadata ? data.queryMetadata.distance : null;
        this.state = data.state;
        this.final_price = data.final_price;
        this.orderedBy = data.orderedBy;
    }
}

