import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import * as firebaseApp from 'firebase/app';
import * as geofirex from 'geofirex';


@Injectable()
export class GeoService {

  geo = geofirex.init(firebaseApp);
  radius = new BehaviorSubject(1.0);

  constructor(private afs: AngularFirestore) {
   }

   getPoint(lat: any, long: any) {
     return this.geo.point(lat, long);
   }

}
