import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CustomPreloading } from './custom.loader';
import { AuthGuard } from './core/auth.guard';
import { LoginComponent } from './shared/login/login.component';
import { OtherInfosModalComponent } from './user-profile/profile/other-infos-modal/other-infos-modal.component';
import { SignupComponent } from './shared/signup/signup.component';
import { ProposalConfirmationComponent } from './proposal-confirmation/proposal-confirmation.component';
import { AddressModalInfoComponent } from './user-profile/profile/address-modal-info/address-modal-info.component';
import { AddToCartModalComponent } from './cart/add-to-cart-modal/add-to-cart-modal.component';
import { NgModule } from '@angular/core';
import { ResetPasswordComponent } from './shared/reset-password/reset-password.component';
import { AboutComponent } from './documents/about/about.component';
import { MentionsLegalesComponent } from './documents/mentions-legales/mentions-legales.component';
import { CGUComponent } from './documents/cgu/cgu.component';
import { CharteQualiteComponent } from './documents/charte-qualite/charte-qualite.component';
import { HygieneCharterComponent } from './documents/hygiene-charter/hygiene-charter.component';
import { CooksCharterComponent } from './documents/cooks-charter/cooks-charter.component';


const routes: Routes = [

  {
    path: 'search',
    loadChildren:
      '../app/search/search.module#SearchModule'
  },
  {
    path: 'login',
    component: LoginComponent,
    outlet: 'modal'
  },
  {
    path: 'signup',
    component: SignupComponent,
    outlet: 'modal'
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent
  },
  {
    path: 'other-info',
    component: OtherInfosModalComponent,
    outlet: 'modal'
  },
  {
    path: 'add-to-cart/:adTitle',
    component: AddToCartModalComponent,
    outlet: 'modal'
  },
  {
    path: 'address-info',
    component: AddressModalInfoComponent,
    outlet: 'modal'
  },
  {
    path: 'ad-details',
    loadChildren:
      '../app/ad-details/ad-details.module#AdDetailsModule',
    data: { preload: true }
  },
  {
    path: 'customized-order-details',
    loadChildren:
      '../app/customized-order-details/customized-order-details.module#CustomizedOrderDetailsModule',
    data: { preload: true }
  },
  {
    path: 'new-ad',
    loadChildren:
      '../app/new-ad/new-ad.module#NewAdModule',
    data: { preload: true }
  },
  {
    path: 'customize-your-order',
    loadChildren:
      '../app/customize-your-order/customize-your-order.module#CustomizeYourOrderModule',
    data: { preload: true }
  },
  {
    path: 'update-your-order',
    loadChildren:
      '../app/customize-your-order/customize-your-order.module#CustomizeYourOrderModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'update-ad',
    loadChildren:
      '../app/new-ad/new-ad.module#NewAdModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'vendor-info',
    loadChildren:
      '../app/vendor-info/vendor-info.module#VendorInfoModule'
  },
  {
    path: 'cart',
    loadChildren:
      '../app/cart/cart.module#CartModule'
  },
  {
    path: 'user-profile',
    loadChildren:
      '../app/user-profile/user-profile.module#UserProfileModule',
  //  canActivate: [AuthGuard]
  },
  {
    path: 'checkout',
    loadChildren:
      '../app/checkout/checkout.module#CheckoutModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'confirmation',
    loadChildren:
      '../app/confirmation/confirmation.module#ConfirmationModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'evaluation',
    loadChildren:
      '../app/evaluation/evaluation.module#EvaluationModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'proposal-confirmation',
    component: ProposalConfirmationComponent
  },
  {
    path: 'about',
    component: AboutComponent
  },
  {
    path: 'mentions-legales',
    component: MentionsLegalesComponent
  },
  {
    path: 'cgu',
    component: CGUComponent
  },
  {
    path: 'charte-qualite',
    component: CharteQualiteComponent
  },
  {
    path: 'hygiene-charter',
    component: HygieneCharterComponent
  },
  {
    path: 'cook-charter',
    component: CooksCharterComponent
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'search'
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: '/search'
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: false,
    scrollPositionRestoration: 'enabled'
// preloadingStrategy: CustomPreloading,
// scrollPositionRestoration: 'enabled'

  })],
  exports: [RouterModule],
  declarations: []
})

export class AppRoutingModule { }

