import { GeoService } from './../../../core/geo.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { Component, OnInit, NgZone, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../../../core/auth.service';
import { User } from '../../../models/user';
import { Subscription } from 'rxjs/Subscription';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AlertService } from 'ngx-alerts';
import { ErrorMsgService } from 'src/app/shared/error-msg/error-msg.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-other-infos',
  templateUrl: './other-infos.component.html',
  styleUrls: ['./other-infos.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OtherInfosComponent implements OnInit, OnDestroy {
  address: any;
  lat: any;
  lng: any;
  center: any;
  otherInfoForm: FormGroup;
  user: User = new User();
  userRef: AngularFirestoreDocument<any>;
  submitted = false;
  private subscription: Subscription;

  constructor(
    private errorMsg: ErrorMsgService,
    private ngxSpinnerService: NgxSpinnerService,
    private zone: NgZone,
    private formBuilder: FormBuilder,
    public auth: AuthService,
    private afs: AngularFirestore,
    private geoService: GeoService,
    private alertService: AlertService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.errorMsg.remove();
    this.otherInfoForm = this.formBuilder.group({
      phone: ['', Validators.required],
      address: ['', Validators.required]
    });
    this.otherInfoForm.updateValueAndValidity();
    this.subscription = this.auth.user.subscribe((user) => {
      if (user) {
        this.user = user;
        this.f.phone.patchValue(user.phone_number);
        this.f.address.patchValue(user.address);
        this.userRef = this.afs.doc(`user/${this.user.id}`);
      }
    },
      err => {
        this.errorMsg.add(['une erreur technique est survenue, merci de réessayer plus tard']);
      }
    );
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  public updateUserData() {
    const data = <any>{};
    if (this.f.phone.value && this.f.address.value) {
      data.phone_number = this.f.phone.value;
      data.address = this.address;
      data.position = this.center;
      if (this.userRef) {
        this.userRef.set(data, { merge: true }).then((res) => {
          this.ngxSpinnerService.hide();
          this.alertService.success('Données enregistrées');
        },
          () => {
            this.ngxSpinnerService.hide();
            this.errorMsg.add(['une erreur technique est survenue', 'vérifier que vous êtes connectés']);
          });
      } else {
        this.ngxSpinnerService.hide();
        this.errorMsg.add(['une erreur technique est survenue', 'vérifier que vous êtes connectés']);
      }
    }
  }

  saveForm() {
    this.ngxSpinnerService.show();
    this.submitted = true;
    this.otherInfoForm.get('address').setErrors({ ['format']: null });
    this.otherInfoForm.get('address').updateValueAndValidity();
    if (this.f.address.touched && this.f.address.value && !this.center) {
      this.otherInfoForm.get('address').setErrors({ ['format']: true });
    }
    if (this.otherInfoForm.valid) {
      this.updateUserData();
    } else {
      this.errorMsg.add(['Le formulaire contient des erreurs']);
      this.ngxSpinnerService.hide();
    }
  }
  get f() { return this.otherInfoForm.controls; }

  setAddress(addrObj) {
    this.zone.run(() => {
      this.address = addrObj.formatted_address;
      this.lat = addrObj.lat ? addrObj.lat : addrObj.geometry.location.lat();
      this.lng = addrObj.lng ? addrObj.lng : addrObj.geometry.location.lng();
      this.center = this.geoService.getPoint(this.lat, this.lng);
    });
  }


}
