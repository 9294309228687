import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {NgbModal, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent {
  @Input() title: string;
  @Input() message: string;
  @Input() id: string;
  @Output() confirme = new EventEmitter();

  constructor(public activeModal: NgbActiveModal) {}

  onConfirme() {
    this.confirme.emit(this.id);
  }

}
