import { Component, OnInit } from '@angular/core';
import { ErrorMsgService } from './error-msg.service';

@Component({
  selector: 'app-error-msg',
  templateUrl: './error-msg.component.html',
  styleUrls: ['./error-msg.component.scss']
})
export class ErrorMsgComponent implements OnInit {

  hasError = false;
  errors: Array<string> = [];
  idElement: string;

  constructor(private errorMsgService: ErrorMsgService) {
    this.idElement = errorMsgService.idElement;
   }

  ngOnInit() {
    this.errorMsgService.changeEvent.subscribe(message => {
      if(message.length > 0){
        this.hasError = true;
      } else {
        this.hasError = false;
      }

      this.errors = message;
    });
  }

}
