// import { ModuleWithProviders } from '@angular/core';
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from '@angular/router';
import { VendorInfoComponent } from './vendor-info.component';

const routes: Routes = [
    {
        path: "",
        component: VendorInfoComponent
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(routes)]
})
export class vendorInfoRoutingModule { }