import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { AccordionModule } from 'primeng/accordion';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { CustomPreloading } from './custom.loader';
import { AppRoutingModule } from './app.routing';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ButtonModule } from 'primeng/button';
import { environment } from '../environments/environment';
import { AngularFireModule } from 'angularfire2';
import { AngularFireDatabaseModule } from 'angularfire2/database';
import { AngularFireAuthModule } from 'angularfire2/auth';
import { AngularFirestoreModule, SETTINGS } from '@angular/fire/firestore';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/sharedModule';
import { AgmCoreModule } from '@agm/core';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { NgxSpinnerModule} from 'ngx-spinner';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { AlertModule} from 'ngx-alerts';
import { ProfileModule } from './user-profile/profile/profile.module';
import { NgxLoadingModule } from 'ngx-loading';
import { ProposalConfirmationComponent } from './proposal-confirmation/proposal-confirmation.component';
import { CartModule } from './cart/cart.module';
import { StoreModule } from '@ngrx/store';
import { reducers } from './store/reducers';
import { AboutComponent } from './documents/about/about.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import {ScrollDispatchModule} from '@angular/cdk/scrolling';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { CGUComponent } from './documents/cgu/cgu.component';
import { MentionsLegalesComponent } from './documents/mentions-legales/mentions-legales.component';
import { CharteQualiteComponent } from './documents/charte-qualite/charte-qualite.component';
import { HygieneCharterComponent } from './documents/hygiene-charter/hygiene-charter.component';
import { CooksCharterComponent } from './documents/cooks-charter/cooks-charter.component';



@NgModule({
  declarations: [
    AppComponent,
    ProposalConfirmationComponent,
    AboutComponent,
    CGUComponent,
    MentionsLegalesComponent,
    CharteQualiteComponent,
    HygieneCharterComponent,
    CooksCharterComponent
    ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    AccordionModule,
    HttpClientModule,
    NgxSpinnerModule,
    AppRoutingModule,
    NgbModule.forRoot(),
    AlertModule.forRoot({maxMessages: 5, timeout: 6000}),
    ButtonModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule.enablePersistence({ experimentalTabSynchronization: true }),
    AngularFireStorageModule,
    AgmCoreModule.forRoot({
      apiKey: environment.googleMapsKey,
      libraries: ['places']
    }),
    NgxLoadingModule.forRoot({}),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    CoreModule,
    SharedModule,
    ProfileModule,
    CartModule,
    ScrollToModule.forRoot(),
    StoreModule.forRoot(reducers),
    ScrollDispatchModule,
    // StoreDevtoolsModule.instrument({
    //   maxAge: 25, // Retains last 25 states
    //   logOnly: environment.production, // Restrict extension to log-only mode
    // }),
    InfiniteScrollModule
    ],
  providers: [
      CustomPreloading,
      {provide: LocationStrategy, useClass: PathLocationStrategy}

      // { provide: SETTINGS, useValue: environment.firebaseSetting },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }

