import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from 'ngx-alerts';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/core/auth.service';
import { User } from 'src/app/models/user';
import { ErrorMsgService } from '../error-msg/error-msg.service';


@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  passwordForm: FormGroup;
  currentUser: User = new User();
  ngUnsubscribe: Subject<any> = new Subject<any>();
  mode: string;
  actionCode: string;
  actionCodeChecked: boolean;

  validationMessages = {
    password: {
      'required': 'le mot de passe est obligatoire.',
      'pattern': 'Le mot de passe doit contenir au moins une lettre et un chiffre.',
      'minlength': 'le mot de passe doit contenir au moins 8 caractères.',
      'maxlength': 'le mot de passe ne doit pas dépasser 25 caractères.',
    }
  };

  constructor(
    private formBuilder: FormBuilder,
    public auth: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private errorMsg: ErrorMsgService,
    private alertService: AlertService
    ) { }

  ngOnInit() {
      this.activatedRoute.queryParams
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(params => {
          // if we didn't receive any parameters,
          // we can't do anything
          if (!params) { this.router.navigate(['/home']); }

          this.mode = params['mode'];
          this.actionCode = params['oobCode'];

          switch (params['mode']) {
            case 'resetPassword': {
              // Verify the password reset code is valid.

              this.auth.verifyPassword(this.actionCode).then(email => {
                this.actionCodeChecked = true;
              }).catch(e => {
                // Invalid or expired action code. Ask user to try to reset the password
                // again.
                this.errorMsg.add(['une erreur technique est survenue, merci de réessayer plus tard']);
                this.router.navigate(['/search']);
           //     this.router.navigate([{ outlets: { 'modal': ['login'] } }]);
              });
            } break;
            case 'recoverEmail': {
            } break;
            case 'verifyEmail': {
            } break;
            default: {
              this.errorMsg.add(['une erreur technique est survenue, merci de réessayer plus tard']);
              this.router.navigate(['/search']);
            }
          }
        });

    this.errorMsg.remove();
    this.passwordForm = this.formBuilder.group({
      NewPassword1: ['', [
        Validators.required,
        Validators.pattern(/^(?=\D*\d)(?=[^a-z]*[a-z]).{8,25}$/),
        Validators.minLength(8),
        Validators.maxLength(25),
      ]],
      NewPassword2: ['', [
        Validators.required,
        Validators.pattern(/^(?=\D*\d)(?=[^a-z]*[a-z]).{8,25}$/),
        Validators.minLength(8),
        Validators.maxLength(25),
      ]]
    });
  }

  ngOnDestroy() {
    // End all subscriptions listening to ngUnsubscribe
    // to avoid memory leaks.
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  /**
   * Attempt to confirm the password reset with firebase and
   * navigate user back to home.
   */
  saveForm() {
    if (this.passwordForm.valid) {

    if (this.f.NewPassword1.value !== this.f.NewPassword2.value) {
      this.errorMsg.add(['Le nouveau mot de passe et sa confirmation ne correspondent pas']);
      return;
    }
    // Save the new password.
    this.auth.confirmPasswordReset(
        this.actionCode,
        this.f.NewPassword1.value
    )
    .then(resp => {
      // Password reset has been confirmed and new password updated.
      this.alertService.success('le nouveau mot de passe à été sauvegardé');
      this.router.navigate(['/search']);
     // this.router.navigate([{ outlets: { 'modal': ['login'] } }]);
    }).catch(e => {
      // Error occurred during confirmation. The code might have
      // expired or the password is too weak.
      this.errorMsg.add(['une erreur technique est survenue, merci de réessayer plus tard']);
      this.router.navigate(['/search']);
    });
  }
  }

  get f() { return this.passwordForm.controls; }
}
