import { createReducer, on } from '@ngrx/store';
import { INCREMENT, DECREMENT, RESET, GETCOUNTER, SETCOUNTER } from '../actions/counter.actions';

export const initialState = 0;

const _counterReducer = createReducer(initialState,
  on(SETCOUNTER, (state, {payload}) => payload),
  on(GETCOUNTER, state => state),
  on(INCREMENT, (state, {payload}) => state + payload),
  on(DECREMENT, (state, {payload}) => state - payload),
  on(RESET, state => 0),
);

export function counterReducer(state, action) {
  return _counterReducer(state, action);
}
