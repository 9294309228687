import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../core/auth.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

type UserFields = 'email' | 'password';
type FormErrors = { [u in UserFields]: string };

@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss']
})
export class LoginModalComponent implements OnInit {

  error: any;
  userForm: FormGroup;
  passReset = false;
  formErrors: FormErrors = {
    'email': '',
    'password': '',
  };
  validationMessages = {
    'email': {
      'required': 'L\'adresse email est obligatoire.',
      'email': 'L\'adresse email est invalide.',
    },
    'password': {
      'required': 'Le mot de passe est obligatoire.',
      'pattern': 'Le mot de passe doit contenir au moins une lettre et un chiffre.',
      'minlength': 'Le mot de passe doit contenir au moins 8 caractères.',
      'maxlength': 'Le mot de passe ne doit pas dépasser 25 caractères.'
    },
  };


  constructor(
    private router: Router,
    public authen: AuthService,
    private fb: FormBuilder,
  ) { }

  ngOnInit() {
    this.buildForm();
  }

  async login() {
    await this.authen.emailLogin(this.userForm.value['email'], this.userForm.value['password']);
    return await this.afterSignIn();

  }

  resetPassword() {
    this.authen.resetPassword(this.userForm.value['email'])
      .then(() => this.passReset = true);
  }

  buildForm() {
    this.userForm = this.fb.group({
      'email': ['', [
        Validators.required,
        Validators.email,
      ]],
      'password': ['', [
        Validators.pattern(/^(?=\D*\d)(?=[^a-z]*[a-z]).{8,25}$/),
        Validators.minLength(8),
        Validators.maxLength(25),
      ]],
    });

    this.userForm.valueChanges.subscribe((data) => this.onValueChanged(data));
    this.onValueChanged(); // reset validation messages
  }
  onValueChanged(data?: any) {
    if (!this.userForm) { return; }
    const form = this.userForm;
    for (const field in this.formErrors) {
      if (Object.prototype.hasOwnProperty.call(this.formErrors, field) && (field === 'email' || field === 'password')) {
        // clear previous error message (if any)
        this.formErrors[field] = '';
        const control = form.get(field);
        if (control && control.dirty && !control.valid) {
          const messages = this.validationMessages[field];
          if (control.errors) {
            for (const key in control.errors) {
              if (Object.prototype.hasOwnProperty.call(control.errors, key) ) {
                this.formErrors[field] += `${(messages as {[key: string]: string})[key]} `;
              }
            }
          }
        }
      }
    }
  }

  // async signInWithGoogle() {
  //   await this.authen.googleLogin();
  //   return await this.afterSignIn();
  // }

  // async signInWithFacebook() {
  //   await this.authen.facebookLogin();
  //   await this.afterSignIn();
  // }

  // async signInWithTwitter() {
  //   await this.authen.twitterLogin();
  //   return await this.afterSignIn();
  // }


  private afterSignIn() {
    this.router.navigate([{outlets: {modal: null}}]);
  }

  public navigateToSignUp() {
    this.router.navigate([{outlets: { 'modal': ['signup'] }}]);
  }
}
