import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { User } from '../models/user';
import { Ad } from '../models/ad';
import { Subscription } from 'rxjs/Subscription';
import { ChatService } from '../core/chat.service';
import { ErrorMsgService } from '../shared/error-msg/error-msg.service';
import { AuthService } from '../core/auth.service';
import { Observable } from 'rxjs';
import { VendorInfoService } from './vendor-info.service';

@Component({
  selector: 'app-vendor-info',
  templateUrl: './vendor-info.component.html',
  styleUrls: ['./vendor-info.component.scss']
})
export class VendorInfoComponent implements OnInit, OnDestroy {
  @Input() userObservable: Observable<User>;
  ads$: Observable<any>;
  displayMenus = true;
  displayOpinions = false;
  private subscription: Subscription;
  limitAds = 6;
  displayPhone = false;

  constructor(
    private vendorInfoService: VendorInfoService,
    public cs: ChatService,
    private errorMsg: ErrorMsgService,
    private auth: AuthService
  ) { }

  ngOnInit() {
    this.userObservable.subscribe(user => {
      this.errorMsg.remove();
      this.ads$ = this.getAds(user.id);
    });
  }

  getAds(userId): Observable<Ad[]> {
    return this.vendorInfoService.getAdsByUserId(userId, this.limitAds);
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  showMenus() {
    this.displayMenus = true;
    this.displayOpinions = false;
  }
  showOpinions() {
    this.displayMenus = false;
    this.displayOpinions = true;
  }

  goToChat(receiverId) {
    this.auth.user.subscribe(user => {
      if (user) {
        if (user.id !== receiverId) {
          this.cs.create(receiverId);
        } else {
          this.errorMsg.add(['une erreur est survenue']);
        }
      }
    });
  }

}
