import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';


@Injectable()

export class SpecialitieService  {

  constructor( private afs: AngularFirestore) {
   }
   public getSpecialityByURL(url: any) {
    return this.afs.doc(url);
   }

   public getAllSpecialities() {

   return this.afs.collection('speciality').get();
   }
}
