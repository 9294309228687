import { Injectable, Injector } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import { fromPromise } from 'rxjs/observable/fromPromise';

import { AuthService } from './auth.service';
import { Observable } from 'rxjs/Observable';
import { switchMap } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';

import * as firebase from 'firebase/app';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    auth: AuthService;

    constructor(private inj: Injector, private afAuth: AngularFireAuth) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (request.url.indexOf('oauthCallback') > -1) {
            return next.handle(request);
        }

        this.auth = this.inj.get(AuthService); // inject the authservice manually (see https://github.com/angular/angular/issues/18224)
        return this.auth.getUserIdToken().pipe(
            switchMap(token => {
                request = request.clone({
                    setHeaders: {
                        Authorization: `Bearer ${token}`
                    }
                });

                return next.handle(request);
            })
        );
    }
}
