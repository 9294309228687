import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { switchMap, map } from 'rxjs/operators';
import { fromPromise } from 'rxjs/observable/fromPromise';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';

import { Customer, Source, Charge, StripeObject } from '../models/checkout';


@Injectable({
  providedIn: 'root'
})
export class CheckoutService {

  readonly api = `${environment.functionsURL}/app`;
  private stripe = Stripe(environment.stripePublishable);
  elements: any;

  constructor(private http: HttpClient) {
    this.elements = this.stripe.elements({locale: 'fr'});
  }

  ///// RETRIEVE DATA ////

  // Get customer data
  getCustomer(): Observable<Customer> {
    const url = `${this.api}/customer/`;

    return this.http.get<Customer>(url);
  }

  // Get a list of charges
  getCharges(): Observable<Charge[]> {
    const url = `${this.api}/charges/`;

    return this.http.get<StripeObject>(url).map(res => res.data);
  }


  ///// PAYMENT ACTIONS ////


  createCharge(card: any, amount: number): Observable<Charge> {
    const url = `${this.api}/charges/`;

    return fromPromise<any>( this.stripe.createSource(card, {currency: 'eur'}) ).pipe(
      switchMap((data: Source) => {
        return this.http.post<Charge>(url, { amount, sourceId: data.source.id, currency: 'eur' });
      })
    );
  }

  createChargeFromSource( idSource, amount): Observable<Charge> {
    const url = `${this.api}/charges/`;
    return this.http.post<Charge>(url, { amount, sourceId: idSource });

  }

  // Saves a payment source to the user account that can be charged later
  attachSource(card: any): Observable<Source> {
    const url = `${this.api}/sources/`;

    return fromPromise<any>( this.stripe.createSource(card) ).pipe(
      switchMap((data: Source) => {
        return this.http.post<Source>(url, { sourceId: data.source.id });
      })
    );
  }

  deleteSource(sourceId: any): Observable<any> {
    const url = `${this.api}/sources/${sourceId}`;

        return this.http.delete<any>(url);
  }

}
